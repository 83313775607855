import storageMap from '../../storage/storageMap'
import storage from '../../storage'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'

/**
 * Determine if a node is eligible (enabled) for the calculation
 *
 * @param {Object} node
 * @param {String} scoreSetType
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export function isNodeEligibleForCal(node, nodeDefinition, scoreSetType = null) {
  return node.is_enabled
}

function setNormalizedScores(defKeys, nodeDefinitions, productNodes) {
  const configMaxScore = JSON.parse(global.env.config.max_product_score)
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { id } = def
    const node = productNodes[id]
    const { score, maxScore } = node
    node.normalizedScore = (score * configMaxScore) / maxScore
  }
}

export function doCreateScorePanel(scoring, nodes, defs) {
  const nodeDefinitionsKeys = Object.keys(defs)
  const treeMap = global.env.treeMap.split(',')
  let scorePanel = []

  for (let i = 0; i < nodeDefinitionsKeys.length; i += 1) {
    const key = nodeDefinitionsKeys[i]
    const def = defs[key]
    const { type, name } = def
    if (type === treeMap[0]) {
      const instance = nodes[key]
      const { normalizedScore } = instance

      scorePanel.push({
        name: name.en,
        value: normalizedScore,
        is_enabled: instance.is_enabled,
      })
    }
  }

  const allItem = { name: 'ALL', value: 0 }
  let scoreSum = 0
  scorePanel.forEach(panel => {
    scoreSum += panel.value
  })
  allItem.value = scoreSum / scorePanel.length
  scorePanel = [allItem, ...scorePanel]
  return scorePanel
}

export async function doCalculation(scoring, nodeDefinitions, productNodes, skipScoringPanel) {
  // We need [perimeter,family,subfamily, item]
  const configMaxScore = JSON.parse(global.env.config.max_product_score)
  const { id: scoringId } = scoring
  const treeMap = global.env.treeMap.split(',')
  treeMap.reverse()

  const criteria = []
  const demerits = []
  const perimeters = []

  const defKeys = Object.keys(nodeDefinitions)

  // Get Criteria and Demerits
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { type, id } = def
    const pNode = productNodes[id]

    if (type === 'perimeter') {
      perimeters.push(def)
    }

    if (type === nodeDefinitionTypeMap.criterion && pNode.is_enabled === true) {
      if (def.bonus_demerit === false) {
        criteria.push(def)
      } else {
        demerits.push(def)
      }
    }
  }

  // Empty all scores but criteria
  Object.keys(productNodes).forEach(nodeDefinitionId => {
    const node = productNodes[nodeDefinitionId]
    const { type } = nodeDefinitions[node.node_definition_id]
    if ([nodeDefinitionTypeMap.criterion].indexOf(type) === -1) {
      node.score = 0
      node.maxScore = 0
      node.percentage = 0
      node.denominator = 0
      node.normalizedScore = 0
      node.scored = 0
      node.notScored = 0
      node.percentageScored = 0
      if (type !== 'item') {
        node.itemsCount = 0
      }
    }
  })

  // STEP 1
  // -----------------------------------------------------------------------------------------
  // Note: Treemap is reversed so [criterions, items, subfamily, family, perimeter]
  for (let t = 0; t < treeMap.length; t += 1) {
    for (let d = 0; d !== defKeys.length; d += 1) {
      const nodeDef = nodeDefinitions[defKeys[d]]
      const actualNode = productNodes[nodeDef.id]

      if (actualNode.is_enabled === true) {
        if (nodeDef.type === treeMap[t] && nodeDef.bonus_demerit === false) {
          // If we are above critera score & maxscore must be calculated from before calcs
          productNodes[nodeDef.id].score = t === 0 ? actualNode.score : actualNode.score / actualNode.denominator
          productNodes[nodeDef.id].maxScore =
            t === 0 ? JSON.parse(global.env.config.max_score) : actualNode.maxScore / actualNode.denominator
          productNodes[nodeDef.id].percentage =
            (productNodes[nodeDef.id].score * 100) / productNodes[nodeDef.id].maxScore

          // Only until families
          if (t < treeMap.length - 1) {
            // Some counters
            if (
              (t === 0 && (!actualNode.is_enabled || actualNode.is_default)) ||
              (t !== 0 && (!actualNode.is_enabled || actualNode.scored === 0))
            ) {
              productNodes[nodeDef.parent_id].notScored += 1
            } else {
              productNodes[nodeDef.parent_id].scored += 1
            }
            productNodes[nodeDef.parent_id].itemsCount += 1

            // Add actual node values to parent values
            productNodes[nodeDef.parent_id].score += productNodes[nodeDef.id].score * nodeDef.weight
            productNodes[nodeDef.parent_id].maxScore += productNodes[nodeDef.id].maxScore * nodeDef.weight
            productNodes[nodeDef.parent_id].denominator += nodeDef.weight
          } // Only until families
        } // Only nodes of actual level
      }
    } // For each nodeDef
  } // For each treeMap level

  // STEP 1.2 GUESS DEMERITS SCORED / UNSCORED
  for (let d = 0; d < demerits.length; d += 1) {
    const demerit = demerits[d]
    const node = productNodes[demerit.id]
    let fatherNodeDefinitionId = demerit.parent_id
    for (let t = 0; t < treeMap.length + 1; t += 1) {
      const father = nodeDefinitions[fatherNodeDefinitionId]
      if (father) {
        if (!node.is_enabled || node.is_default) {
          productNodes[father.id].notScored += 1
        } else {
          productNodes[father.id].scored += 1
        }
        fatherNodeDefinitionId = father.parent_id
      }
    }
  }

  // STEP 4
  // -----------------------------------------------------------------------------------------
  // Calculate Normalized scores
  setNormalizedScores(defKeys, nodeDefinitions, productNodes)

  // STEP 5
  // -----------------------------------------------------------------------------------------
  // For every node but criterion calc scored percentage
  for (let i = 0; i !== defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type !== nodeDefinitionTypeMap.criterion) {
      const { scored, notScored } = productNodes[def.id]
      const tot = scored + notScored
      if (tot > 0) {
        productNodes[def.id].percentageScored = (scored / tot) * 100
      }
    }
  }

  // STEP 6
  // -----------------------------------------------------------------------------------------
  // For each criteria, calc score contribution
  const calcGroupWeight = nodeDef => {
    let children = 0
    let groupWeight = 0

    nodeDef.children_ids.forEach(_id => {
      const _node = productNodes[_id]
      if (_node.is_enabled) {
        children += 1
        const _nodeDef = nodeDefinitions[_id]
        groupWeight += _nodeDef.weight
      }
    })

    return children === 0 ? 0 : groupWeight
  }

  criteria.forEach(criterion => {
    const node = productNodes[criterion.id]
    const item = nodeDefinitions[criterion.parent_id]
    const subfamily = nodeDefinitions[item.parent_id]
    const family = nodeDefinitions[subfamily.parent_id]
    const perimeter = nodeDefinitions[family.parent_id]

    const criteriaWeightInItem = calcGroupWeight(item)
    const itemsWeightInSubfamily = calcGroupWeight(subfamily)
    const subfamiliesWeightInFamily = calcGroupWeight(family)
    const familiesWeightInPerimeter = calcGroupWeight(perimeter)

    if (
      criteriaWeightInItem === 0 ||
      itemsWeightInSubfamily === 0 ||
      subfamiliesWeightInFamily === 0 ||
      familiesWeightInPerimeter === 0
    ) {
      return
    }

    const fullWeight =
      (family.weight / familiesWeightInPerimeter) *
      (subfamily.weight / subfamiliesWeightInFamily) *
      (item.weight / itemsWeightInSubfamily) *
      (criterion.weight / criteriaWeightInItem)

    const normalizedMaxScoreContribution = configMaxScore * fullWeight * (1 / node.maxScore) * node.maxScore
    const normalizedScoreContribution = configMaxScore * fullWeight * (1 / node.maxScore) * node.score

    productNodes[criterion.id].normalizedMaxScoreContribution = normalizedMaxScoreContribution
    productNodes[criterion.id].normalizedScoreContribution = normalizedScoreContribution
  })

  if (skipScoringPanel) {
    return productNodes
  }

  // STEP 7
  // -----------------------------------------------------------------------------------------
  // Update offline_scorings_list to view score panel updated on search
  const scorePanel = doCreateScorePanel(scoring, productNodes, nodeDefinitions)
  const offlineScoring = await storage.get(storageMap.offline_scorings_list, scoringId)
  if (offlineScoring) {
    offlineScoring._source.score_panel = scorePanel
    await storage.update(storageMap.offline_scorings_list, offlineScoring)
  }

  return productNodes
}

// eslint-disable-next-line no-unused-vars
export async function doCalculationForAnalytics(nodeDefinitions, productNodes) {
  // Not necessary
}
